import {
  ArrowRight,
  Eye,
  Mail,
  MessageSquare,
  Settings,
  Share,
  TicketMinus,
  UserRound,
} from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  NotificationBadge,
} from '../../../../components';

import MenuIcon from '../MenuIcon';
import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';

import css from './TopbarDesktop.module.css';
import { createSlug } from '../../../../util/urlHelpers';
import { useLocation } from 'react-router-dom';

const ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

const baseURL = useDevApiServer ? `http://localhost:${port}` : ROOT_URL;

const SignupLink = () => {
  const redirect = `${baseURL}/api/auth/auth0?isLogin=${false}&userType=provider&defaultReturn=${encodeURIComponent(
    '/'
  )}`;

  return (
    <button onClick={e => (window.location = redirect)} className={css.topbarLinkSignup}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.becomeProvider" />
        <ArrowRight />
      </span>
    </button>
  );
};

const LoginLink = () => {
  const location = useLocation();

  const redirect = `${baseURL}/api/auth/auth0?from=${encodeURIComponent(
    location.pathname
  )}&isLogin=${true}&defaultReturn=${encodeURIComponent('/')}`;

  return (
    <button onClick={e => (window.location = redirect)} className={css.topbarLinkLogin}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.login" />
        <ArrowRight />
      </span>
    </button>
  );
};

const InboxLink = ({ notificationCount, currentUserIsProvider }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: currentUserIsProvider ? 'sales' : 'orders' }}
    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const ProfileMenu = ({ currentPage, currentUser, onLogout, notificationCount }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const inboxTab =
    currentUser?.attributes?.profile?.publicData?.userType === 'provider' ? 'sales' : 'orders';

  const authorSlug = createSlug(currentUser?.attributes?.profile?.displayName);
  return (
    <div className={css.profileMenu}>
      {currentUser && (
        <NamedLink
          name="ProfilePageWithSlug"
          params={{ id: currentUser?.id?.uuid, slug: authorSlug }}
        >
          <span className={css.hey}>
            <FormattedMessage id="Topbar.hey" />
          </span>
          <span className={css.firstName}>{currentUser.attributes?.profile?.firstName}</span>
        </NamedLink>
      )}
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <div className={css.menuIcon}>
            <MenuIcon />
            <NotificationBadge className={css.menuNotificationBadge} count={notificationCount} />
          </div>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="ProfileLogout">
            {currentUser && (
              <NamedLink
                className={classNames(
                  css.menuLink,
                  css.profileLink,
                  currentPageClass('ProfilePage')
                )}
                name="ProfilePageWithSlug"
                params={{ id: currentUser?.id?.uuid, slug: authorSlug }}
              >
                <FormattedMessage id="TopbarDesktop.profileLink" />
              </NamedLink>
            )}
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarDesktop.logout" />
              <ArrowRight className={css.logoutIcon} />
            </InlineTextButton>
          </MenuItem>
          {/* <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
              name="ProfileSettingsPage"
            >
              <div className={css.iconWrapper}>
                <UserRound />
              </div>
              <FormattedMessage id="TopbarDesktop.editProfileLink" />
            </NamedLink>
          </MenuItem> */}
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <div className={css.iconWrapper}>
                <TicketMinus />
              </div>
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="InboxPage">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass(`InboxPage:${inboxTab}`))}
              name="InboxPage"
              params={{ tab: inboxTab }}
            >
              <div className={css.inboxIconDotWrapper}>
                <div className={css.iconWrapper}>
                  <MessageSquare />
                </div>

                <NotificationBadge
                  className={css.inboxNotificationBadge}
                  count={notificationCount}
                />
              </div>

              <FormattedMessage id="TopbarDesktop.inboxLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="SocialSharePage">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('SocialSharePage'))}
              name="SocialSharePage"
            >
              <div className={css.iconWrapper}>
                <Share />
              </div>
              <FormattedMessage id="TopbarDesktop.socialShareLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(
                css.menuLink,
                css.settingsLink,
                currentPageClass('AccountSettingsPage')
              )}
              name="AccountSettingsPage"
            >
              <div className={css.iconWrapper}>
                <Settings />
              </div>
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>
        </MenuContent>
      </Menu>
    </div>
  );
};

const TopbarDesktop = props => {
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const currentUserIsProvider = currentUserHasListings;
  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || css.root, className);

  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserIsProvider={currentUserIsProvider}
    />
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu
      currentPage={currentPage}
      currentUser={currentUser}
      onLogout={onLogout}
      notificationCount={notificationCount}
    />
  ) : null;

  const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        linkToExternalSite={config?.topbar?.logoLink}
      />
      <TopbarSearchForm
        className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={onSearchSubmit}
        initialValues={initialSearchFormValues}
        appConfig={config}
      />

      {/* <CustomLinksMenu
        currentUser={currentUser}
        currentPage={currentPage}
        customLinks={customLinks}
        intl={intl}
        hasClientSideContentReady={authenticatedOnClientSide || !isAuthenticatedOrJustHydrated}
      /> */}

      <div className={css.primaryLinks}>
        {/* {inboxLinkMaybe} */}
        {profileMenuMaybe}
        {signupLinkMaybe}
        {loginLinkMaybe}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
