/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';
import { createSlug } from '../../../../util/urlHelpers';

import {
  ExternalLink,
  LinkedLogo,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';
import {
  ArrowRight,
  Eye,
  MessageSquare,
  Settings,
  Share,
  TicketMinus,
  UserRound,
} from 'lucide-react';
import { useLocation } from 'react-router-dom';

const CustomLinkComponent = ({ linkConfig, currentPage }) => {
  const { group, text, type, href, route } = linkConfig;
  const getCurrentPageClass = page => {
    const hasPageName = name => currentPage?.indexOf(name) === 0;
    const isCMSPage = pageId => hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
    const isInboxPage = tab => hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
    const isCurrentPage = currentPage === page;

    return isCMSPage(route?.params?.pageId) || isInboxPage(route?.params?.tab) || isCurrentPage
      ? css.currentPage
      : null;
  };

  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    // Internal link
    const { name, params, to } = route || {};
    const className = classNames(css.navigationLink, getCurrentPageClass(name));
    return (
      <NamedLink name={name} params={params} to={to} className={className}>
        <span className={css.menuItemBorder} />
        {text}
      </NamedLink>
    );
  }
  return (
    <ExternalLink href={href} className={css.navigationLink}>
      <span className={css.menuItemBorder} />
      {text}
    </ExternalLink>
  );
};

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    customLinks,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const extraLinks = customLinks.map(linkConfig => {
    return (
      <CustomLinkComponent
        key={linkConfig.text}
        linkConfig={linkConfig}
        currentPage={currentPage}
      />
    );
  });

  const ROOT_URL = process.env.REACT_APP_MARKETPLACE_ROOT_URL;
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  const baseURL = useDevApiServer ? `http://localhost:${port}` : ROOT_URL;

  const SignupLink = () => {
    const redirect = `${baseURL}/api/auth/auth0?isLogin=${false}&userType=provider&defaultReturn=${encodeURIComponent(
      '/'
    )}`;

    return (
      <button onClick={e => (window.location = redirect)} className={''}>
        <span className={css.signupLink}>
          <FormattedMessage id="TopbarMobileMenu.becomeProvider" />
          <ArrowRight />
        </span>
      </button>
    );
  };

  const LoginLink = () => {
    const location = useLocation();

    const redirect = `${baseURL}/api/auth/auth0?from=${encodeURIComponent(
      location.pathname
    )}&isLogin=${true}&defaultReturn=${encodeURIComponent('/')}`;

    return (
      <button onClick={e => (window.location = redirect)} className={css.loginLink}>
        <span className={css.topbarLinkLabel}>
          <FormattedMessage id="TopbarMobileMenu.loginLink" />
          <ArrowRight />
        </span>
      </button>
    );
  };

  if (!isAuthenticated) {
    const signup = <SignupLink />;

    const login = <LoginLink />;

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>

          <div className={css.customLinksWrapper}>{extraLinks}</div>

          <div className={css.spacer} />
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="ProviderOnboardingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage = currentPage?.indexOf('InboxPage') === 0 && page?.indexOf('InboxPage') === 0;
    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';
  const authorSlug = createSlug(currentUser?.attributes?.profile?.displayName);

  return (
    <div className={css.root}>
      <div className={css.content}>
        <LinkedLogo
          className={css.logo}
          rootClassName={css.logoRoot}
          logoImageClassName={css.logoImage}
          layout={'mobile'}
          alt={'logo'}
        />
        {currentUser && (
          <NamedLink
            name="ProfilePageWithSlug"
            params={{ id: currentUser?.id?.uuid, slug: authorSlug }}
          >
            <div className={css.greeting}>
              <span className={css.hey}>
                <FormattedMessage id="Topbar.hey" />
              </span>
              <span className={css.firstName}>{displayName}</span>
            </div>
          </NamedLink>
        )}
        <h1 className={css.menuHeading}>
          <FormattedMessage id="TopbarMobileMenu.heading" />
        </h1>
        <div className={css.accountLinksWrapper}>
          {/* <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <div className={css.iconWrapper}>
              <UserRound />
            </div>
            <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
          </NamedLink> */}
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <div className={css.iconWrapper}>
              <TicketMinus />
            </div>
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>

          <NamedLink
            className={classNames(css.menuLink, currentPageClass(`InboxPage:${inboxTab}`))}
            name="InboxPage"
            params={{ tab: inboxTab }}
          >
            <div className={css.iconWrapper}>
              <MessageSquare />
            </div>
            <FormattedMessage id="TopbarMobileMenu.inboxLink" />
            {notificationCountBadge}
          </NamedLink>

          <NamedLink
            className={classNames(css.menuLink, currentPageClass('SocialSharePage'))}
            name="SocialSharePage"
          >
            <div className={css.iconWrapper}>
              <Share />
            </div>
            <FormattedMessage id="TopbarMobileMenu.socialShareLink" />
          </NamedLink>

          <hr className={css.menuDivider}></hr>

          <div>
            {currentUser && (
              <NamedLink
                className={classNames(
                  css.menuLink,
                  css.profileLink,
                  currentPageClass('ProfilePage')
                )}
                name="ProfilePageWithSlug"
                params={{ id: currentUser?.id?.uuid, slug: authorSlug }}
              >
                <FormattedMessage id="TopbarMobileMenu.profileLink" />
              </NamedLink>
            )}
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
              <ArrowRight className={css.logoutIcon} />
            </InlineTextButton>
          </div>
        </div>
        <NamedLink
          className={classNames(
            css.menuLink,
            css.settingsLink,
            currentPageClass('AccountSettingsPage')
          )}
          name="AccountSettingsPage"
        >
          <div className={css.iconWrapper}>
            <Settings />
          </div>
          <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
        </NamedLink>
      </div>
      {/* <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div> */}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
