import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const MenuIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMenuIcon, className);

  return (
    <svg className={classes} width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.56348H14.0473" stroke="#242424" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.00781 6.58154H12.3273" stroke="#242424" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 11.5996H13.9039" stroke="#242424" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const { string } = PropTypes;

MenuIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

MenuIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default MenuIcon;
